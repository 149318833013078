import { versions } from './versions';

export const environment = {
  versions,
  production: false,
  hostUrl: '',
  apiUrl: 'https://stage-api.octopusmusicschool.com/api',
  frontendUrl: 'https://stage.octopusmusicschool.com/',
  signalRUrl: 'https://stage-api.octopusmusicschool.com/signalr-octopus-chat',
  persistUserSession: true,
  sentryKey: '', // sentry key not required for local environments
  firebase: {
    apiKey: "AIzaSyCvPrKMb_Zm8E0uG7hysCd-XMwUSwfflY0",
    authDomain: "octopus-stage-58452.firebaseapp.com",
    projectId: "octopus-stage-58452",
    storageBucket: "octopus-stage-58452.firebasestorage.app",
    messagingSenderId: "635199780360",
    appId: "1:635199780360:web:16750bfebb0fc8742803bd"
  },
  swUpdate: false,
  swUpdateFooter: false,
  encryptionService: {
    enable: false,
    secretKey: 'BfBRoYxsRKGnx3DlPgnsmei7qVHTD62o',
    secretIV: 'cGsgbxxqbcYN6HZG'
  },
  compressAfterFileSize: 2
};
